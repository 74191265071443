import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "./App.css"
import { TopNavigation, InfoPanel, ContactLinks, ContactModal } from "./components"
import { PanelView } from './components/InfoPanel';
import { IconColor } from './components/ContactLinks';
import { TypeAnimation } from 'react-type-animation';
// npm run build gcloud app deploy

function App() {
  const [showPanel, setShowPanel] = useState<boolean>(false)
  const [panelView, setPanelView] = useState<PanelView>(PanelView.WorkHistory);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [promptVisible, setPromptVisible] = useState<boolean>(false);
  const [mainDelay, setMainDelay] = useState<boolean>(false)
  setTimeout(() => {
    setPromptVisible(true);
  }, 3250);

  setTimeout(() => {
    setMainDelay(true);
  }, 1000);

  return (
    <>
      <TopNavigation setShowPanel={setShowPanel} setPanelView={setPanelView} setShowContactModal={setShowContactModal} />
      <div className="page">
        <div className="welcome">
          <span className="myLinks"><ContactLinks iconColor={IconColor.White} animate={true} /></span>
          {mainDelay && <span className="typedName">
            <TypeAnimation
              sequence={[
                'Kyle Herrmann'
              ]}
              speed={10}
              style={{ textAlign: "right" }}
              repeat={0}
              cursor={false}
            />
          </span>}
          {promptVisible &&
            <span className="typedPrompt">
              <TypeAnimation
                sequence={[
                  'Full-Stack Engineer | Solutions Consultant'
                ]}
                speed={50}
                style={{ textAlign: "right" }}
                repeat={0}
                cursor={false}
              />
            </span>
          }

        </div>
        <InfoPanel showPanel={showPanel} setShowPanel={setShowPanel} setPanelView={setPanelView} panelView={panelView} />
        <ContactModal showContactModal={showContactModal} setShowContactModal={setShowContactModal} />
      </div >
    </>
  );
}


export default App;
