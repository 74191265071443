import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { PanelView } from '../InfoPanel/InfoPanel';
import "./TopNavigation.css"

interface TopNavigationProps {
  setShowPanel: (toggle: boolean) => void;
  setPanelView: (view: PanelView) => void;
  setShowContactModal: (toggle: boolean) => void;
}

export const TopNavigation = ({ setShowPanel, setPanelView, setShowContactModal }: TopNavigationProps) => {
  return (
    <div className='topNav'>
      <Navbar collapseOnSelect={false} expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>Kyle Herrmann</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Button onClick={() => HandlePanelView(PanelView.WorkHistory)} variant="link">Work History</Button>
              <Button onClick={() => HandlePanelView(PanelView.Skills)} variant="link">Skills</Button>
              <Button onClick={() => window.open("https://www.linkedin.com/in/kyle-m-h/")} variant="link">LinkedIn</Button>
              <Button onClick={() => setShowContactModal(true)} variant="link">Contact</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar >
    </div>
  )
  function HandlePanelView(view: PanelView) {
    setShowPanel(true)
    setPanelView(view)
  }
}
